import React from "react"
import { Link, graphql } from "gatsby"
import "./base-styles.scss"
import "./LayoutCommon"
import { baseClass, childClass } from "./LayoutCommon";
import SiteHeader from "components/headers/SiteHeader";
import { Mdx, FileConnection, MdxConnection } from "@GraphQLModels";
import { Pedal, PedalMaker, PedalCategory } from "@ViewModels";
import { PedalBrowserGrid, PedalBrowserNavigator, PedalBrowserTitleBar, PedalBrowserNavigatorButton, leadInBlockClassName, PedalBrowserContent } from "components/pedal-browser/PedalBrowser";
import MdxBlock from "components/texty/MdxBlock";
import PedalCardList from "components/pedal-browser/PedalCardList";
import PageMeta from "components/texty/PageMeta";
import SiteFooter from "components/footer/SiteFooter";

interface IProps {
  pageContext: {
    id: string,
    pedalRegex: string,
    categoryName: string
  };
  data: {
    mdx: Mdx,
    pedals: MdxConnection,
    allMdx: MdxConnection
  }
}

export default function CategoryPageLayout(props: IProps) {
  const pedals = props.data.pedals ? props.data.pedals.edges.map(x => Pedal.fromMdxNode(x.node)) : [];
  const makers = props.data.allMdx.edges.filter(x => x.node.frontmatter.templateKey == 'maker').map(x => PedalMaker.fromMdxNode(x.node));
  const categories = props.data.allMdx.edges.filter(x => x.node.frontmatter.templateKey == 'category').map(x => PedalCategory.fromMdxNode(x.node));
  const thisCategory = categories.find(x => x.route == `/${props.pageContext.categoryName}/`);
  return (
    <div className={baseClass}>
      <PageMeta
        title={thisCategory.name}
        route={props.data.mdx.fields.route}
        imageUrl={props.data.mdx.frontmatter.mainImage} >
      </PageMeta>

      <SiteHeader />
      <PedalBrowserGrid className={childClass('container')} canHide={true}>
        <PedalBrowserTitleBar title={props.pageContext.categoryName} controls={<PedalBrowserNavigatorButton />} />
        <MdxBlock mdx={props.data.mdx} showTitle={false} className={leadInBlockClassName} />
        <PedalBrowserNavigator
          makers={makers}
          activeMaker={undefined}
          categories={categories}
          activeCategory={thisCategory}
        />
        <PedalBrowserContent>
          <h3 className={childClass('section-seperator-title')}>All {props.data.mdx.frontmatter.title} Pedals</h3>
          <PedalCardList pedals={pedals} displayMakers={true} />
        </PedalBrowserContent>
      </PedalBrowserGrid>
      <SiteFooter />
    </div>
  )
}

/**
 * Query for data for the page. Note that $id is injected in via context from
 * actions.createPage. See gatsby-node.js for more info.
 */
export const pageQuery = graphql`
  query CategoryPageLayout($id: String, $categoryName: String) {
        mdx(id: {eq: $id }) {
        id
        frontmatter {
        title
          mainImage
      }
      fields {
        route
      }
        code {
        body
      }
      }
      pedals: allMdx( filter: {frontmatter: {categories: {eq: $categoryName } } } ) {
        edges {
      node {
        fields {
      route
    }
            frontmatter {
        title
      }
      }
    }
  }
      allMdx( filter: {frontmatter: {templateKey: { in: [ "maker", "category" ] } } } ) {
        edges {
      node {
        fields {
      route
    }
            frontmatter {
        templateKey
              mainImage
      title
    }
  }
}
}
}
`
